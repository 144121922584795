import { Inject, Injectable } from "@angular/core";
import OktaSignIn, { LanguageCode } from "@okta/okta-signin-widget";
import { OktaAuth } from "@okta/okta-auth-js";
import { CacheService } from "../services/cache.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import { AppConfig } from "../config"; 

import { HttpService } from ".././services/http.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class Okta {
  widget;
  public language: string;
  public country: string;
  public baseUrlOkta = AppConfig.baseURLOKTA;
  public clientIDOkta = "";
  public hostName = "";
  public redirect_uri;
  public apiUrlLogin = AppConfig.apiBaseUrlLogin;

  constructor(
    private cacheService: CacheService,
    @Inject(OKTA_AUTH)
    public oktaAuth: OktaAuth,
    private http: HttpService
  ) {

    this.redirect_uri = window.location.origin + "/login/jp";
  }

  getWidget() {
    return new Promise((resolve, reject) => {
      this.widget = new OktaSignIn({
        baseUrl: AppConfig.oktaConfig["issuer"].split("/oauth2")[0],
        clientId: AppConfig.oktaConfig["clientId"],
        redirectUri: AppConfig.oktaConfig["redirectUri"],
        colors: {
          brand: "#001871",
        },
        // Use for dynamic language selection
        // language: (supportedLanguages, userLanguages) => {
        //   // supportedLanguages is an array of languageCodes, i.e.:
        //   // ['cs', 'da', ...]
        //   //
        //   // userLanguages is an array of languageCodes that come from the user's
        //   // browser preferences
        //   const userLangs = userLanguages.slice() as LanguageCode[];
        //   console.log("userLangs: ", userLangs, supportedLanguages);
        //   const matching = userLangs.filter((item) =>
        //     supportedLanguages.includes(item)
        //   );
        //   if (matching) return matching[0];
        // },
        // hardcoded language selection
        language: "ja",
        i18n: {
          en: {
            "primaryauth.title": "Log In or Register",
            "primaryauth.submit": "Log In",
            "factor.sms.description":
              "Enter a single-use code sent to your mobile phone. The mobile phone number provided here will replace any previous SMS phone number.",
            "mfa.backToFactors": "Back",
            "mfa.sent": "Test Sent",
            "mfa.sendCode": "Request code",
            "oform.errormsg.title": "This is a custom message",
            "oform.errorbanner.title": "@#$@#$ asdf",
            "mfa.challenge.enterCode.placeholder": "lkjsdf",
            "mfa.resendCode": "Sample",
            "mfa.challenge.verify": "Validate",
            "model.validation.field.blank": "Field is blank",
            "errors.E0000068": "This is custom",
            "account.unlock.email.or.username.placeholder": "ユーザー名（メールアドレス）",
          },
          ja: {
            "oie.password.challenge.title": "Acmeパスワードで確認する",
            "oie.email.authenticator.description":
              "Acmeリンクまたはコードで確認する",
            "needhelp": "サインインについてお困りですか",
            "oie.enroll.okta_verify.email.notReceived": "メールが届かない場合は、コードの <a href=\"#\" class=\"resend-link\">再送信</a> を選択してください",
            "remember": "ユーザー名をブラウザに記憶する",
            "primaryauth.username.placeholder": "ユーザー名（メールアドレス）",
            "forgotpassword": "パスワードをお忘れの方",
            "unlockaccount": "アカウントのロックを解除される方",
            "goback": "サインインに戻る",
            "oform.verify": "認証する",
            "mfa.sendCode": "コードを送信する",
            "password.forgot.email.or.username.placeholder": "ユーザー名（メールアドレス）",
            "password.forgot.sendText": "SMSでリセットする",
            "password.forgot.sendEmail": "メールでリセットする",
            "password.forgot.emailSent.desc": "{0}宛にメールが送信されました。手順にそってパスワードを設定してください",
            "password.forgot.emailSent.title": "メールが送信されました",
            "account.unlock.sendText": "SMSでリセットする",
            "password.unlock.sendEmail": "メールでリセットする",
            "account.unlock.email.or.username.placeholder": "ユーザー名（メールアドレス）",
            "account.unlock.code.notReceived": " ",
            // "recovery.mobile.hint": "{0}でのリセットは、携帯電話番号が登録されている場合にのみ使用できます",
            "recovery.sms.hint": "SMSでのリセットは、携帯電話番号が登録されている場合にのみ使用できます",
            "mfa.challenge.enterCode.placeholder": "コードを入力します",
            "mfa.sent": "送信済",
            "mfa.resendCode": "コードを再送信する",
            "mfa.challenge.verify": "認証する",
            "password.forgot.code.notReceived": " ",
            // errors
            "oform.errorbanner.title": "コードが一致しません。もう一度お試しください", // can't be made field specific
            "authfactor.challenge.soft_token.invalid_passcode": "コードが一致しません。もう一度お試しください",
            "error.username.required": "ユーザー名（メールアドレス）を入力してください",
            "oie.invalid.recovery.token": "コードが一致しません。もう一度お試しください",
            // "errors.E0000004": "正しいユーザー名、パスワードを入力してください",
            "errors.E0000004": "This is a custom error code.",
            "password.complexity.no_username.description": "ユーザー名（メールアドレス）またその一部は使用できません",
            "password.complexity.no_first_name.description": "お客さまのお名前（姓）を使用することはできません",
            "password.complexity.no_last_name.description": "お客さまのお名前（名）を使用することはできません ",
            "password.complexity.history": "前回と同じパスワードを使用することはできません",
            "oie.password.newPasswordLabel": "パスワード",
            "password.confirmPassword.placeholder": "パスワード（確認用）",
            "oie.password.confirmPasswordLabel": "パスワード（確認用）",
            "oie.password.enroll.title": "パスワードの設定",
            "enroll.choices.description.generic": " ",
            "enroll.choices.list.setup": "必要な設定",
            "enroll.sms.setup": "SMSで認証コードを受信します",
            "enroll.choices.submit.configure": "設定する",
            "model.validation.field.blank":"コードが一致しません。もう一度お試しください",
            "oform.error.unexpected":"コードが一致しません。もう一度お試しください",
            "recoveryChallenge.sms.title": "携帯電話で受信した6桁の番号を入力の上、「認証する」のボタンを押してください。SMSが届かない場合は、サインイン画面に戻り、メールでの認証を行ってください。",
            "mfa.phoneNumber.placeholder": "携帯電話番号",
            "mfa.backToFactors": "前の画面に戻ります",
            "factor.sms.description": "携帯電話に６桁のコードを送信します。そのコードを入力いただき認証を行います。携帯番号を入力すると、以前入力した番号を更新する形になります",
            "password.forgot.email.or.username.tooltip": " ",
            "recovery.mobile.hint": "{0}を受信できる携帯電話番号が登録されている場合のみ使用できます。",
            "factor.sms.time.warning": "SMSが届かない場合は、「コードの再送信」を選択してください。",
            "account.unlock.email.or.username.tooltip": " ",
            "password.complexity.history.description": "前回({0}回前)と同じパスワードを使用することはできません",
            // "password.complexity.no_username.description" :"ユーザー名またはその一部は使用できません",
            // "password.complexity.no_first_name.description" :"お客さまのお名前（姓）を使用することはできません",
            // "password.complexity.no_last_name.description" : "お客さまのお名前（名）を使用することはできません ",
            // "oie.password.newPasswordLabel":"新しいパスワードの入力",
            // "password.confirmPassword.placeholder":"新しいパスワードの再入力",
            // "enroll.choices.description.generic" : "",
            // "enroll.choices.list.setup":"必要な設定",
            // "enroll.choices.submit.configure":"設定する",
            // "mfa.phoneNumber.placeholder":"携帯電話番号",
            // "oform.back" : "前の画面に戻ります",
            // "factor.sms.description":"携帯電話に送信されるコードの入力が必要です",
            "password.newPassword.placeholder": "パスワード",
            "password.newPassword.tooltip": " ",
            "password.confirmPassword.tooltip": " ",
            "password.reset": 'パスワードを設定する',
            "password.error.match": "入力されたパスワードが一致しません",
            "email.enroll.title": "メールによる認証の設定",
            "email.mfa.email.sent.description": "{0}に６桁のコードを送信しました。以下にそのコードを入力いただき認証を行います",
"email.enroll.description": "メールに６桁のコードを送信します。そのコードを入力いただき認証を行います",
"factor.email.description": "メールに６桁のコードを送信します。そのコードを入力いただき認証を行います",
"enroll.choices.title":"多要素認証の設定",
"enroll.choices.optional": "追加で別の認証方法も設定可能です。続けて設定するか「終了する」のボタンを押してください",
"enroll.choices.list.enrolled": "登録済みの認証方法",
"factor.sms": "SMSによる認証",
"enroll.choices.list.optional": "追加で設定可能な認証方法",
"factor.email": "メール認証",
"email.button.send": "コードを送信する",
"mfa.setupOptions": "設定を始める",
"oform.errormsg.title" : "コードが一致しません。もう一度お試しください",
"enroll.choices.submit.finish": "終了する",
"account.unlock.emailSent.title": "メールが送信されました",
"account.unlock.emailSent.desc": "{0}宛にメールが送信されました。手順にそってアカウントロックを解除してください",
"account.unlock.unlocked.title": "アカウントロックが解除されました",
"account.unlock.unlocked.desc": "サインイン画面に戻ってお手続きを開始してください",
"account.unlock.title": "アカウントのロック解除",
"oie.selfservice.unlock_user.success.message": "サインイン画面に戻ってお手続きを開始してください",
"factor.call.description": "音声指示に従って電話を使用して認証します",
"enroll.choices.description": "セキュリティ強化のため多要素認証の設定をお願いしております。AIGから送信するコードを受信する方法を以下から選択ください（複数選択が可能です）",
            "account.unlock.sendEmail": "メールでリセットする",

          },
        },
        authClient: this.oktaAuth as any,
        useInteractionCodeFlow: false,
        features: {
          // registration: true,
          showPasswordToggleOnSignInPage: true,
          selfServiceUnlock: true,
          smsRecovery: true,
          autoPush: true,
          multiOptionalFactorEnroll: true,
          callRecovery: false, // Enable voice call-based account recovery
          emailRecovery: true, // Enable email account recovery
          showPasswordRequirementsAsHtmlList: true,
        },
        helpLinks: {
          help: 'https://www.aig.co.jp/content/dam/aig/sonpo/jp/ja/documents/services/User_guide.pdf',
        },
        // registration: {
        //   click: function () {
        //     window.location.href = "/registration";
        //   },
        // },
      });
      return resolve(this.widget);
    });
  }

  registration(data) {
    const registrationUrl =
      this.apiUrlLogin + "/producer-registration/producers/registrations";
    //  const registrationUrl = 'assets/data/registration.json';
    console.log("registration data  ", data);
    const headers = {
      "Content-Type": "application/json",
      client_id: "6407d34d71a948afa649aab8dcb24cd0",
      Authorization:
        "Basic NjQwN2QzNGQ3MWE5NDhhZmE2NDlhYWI4ZGNiMjRjZDA6YUQyOTMwQjM2Y0YxNEI3Njk3ZTA5YjM5NjdBOTViOWQ=",
    };
    const registrationData = this.http
      .makeHttpPostCall(registrationUrl, data, headers)
      .pipe(map((data) => data.body));
    return registrationData;
  }

  async logout() {
    const configg = {
      url: AppConfig.oktaUrl,
      pkce: true,
      issuer: AppConfig.oktaUrl + "/oauth2/" + AppConfig.authServerID,
      tokenManager: {
        storage: "sessionStorage",
      },
    };
    const authClient = new OktaAuth(configg);
    this.cacheService.clearAll();
    localStorage.setItem("country", this.country);
    localStorage.setItem("language", this.country);
    await authClient.signOut({
      postLogoutRedirectUri: this.redirect_uri,
    });
  }

  public isAuthenticated() {
    return this.oktaAuth.isAuthenticated();
  }
}
